import React from "react";
import { format } from "date-fns";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import Box from "../components/containers/box";
import Video from "../components/video/video";

export const buildDate = (prismicPostDate, dateFormat = `do MMMM yyyy`) => {
  const activeDateArray = prismicPostDate.split(`-`);
  const activeDate = new Date(
    activeDateArray[0],
    parseInt(activeDateArray[1]) - 1,
    activeDateArray[2],
  );
  const formattedDate = format(activeDate, dateFormat);

  return formattedDate;
};

export const truncateString = (string, length = 200, ending = `...`) => {
  if (string.length > length) {
    return string.substring(0, length - ending.length) + ending;
  }

  return string;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const roundNumber = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const formatterNumber = (number) => {
  // Format and round up the cost to 2 decimal points
  const formattedPrice = new Intl.NumberFormat(`en`, {
    minimumFractionDigits: 2,
  }).format(number);

  // If decimal numbers are `00` remove them from returned value
  const split = formattedPrice.split(`.`);
  if (split[1] === `00`) {
    return split[0];
  }

  return formattedPrice;
};

export const formatterPrice = (number, countryCode, currencyCode) => {
  // Format into price and round up the cost to 2 decimal points
  let formattedPrice = new Intl.NumberFormat(`en-${countryCode}`, {
    style: `currency`,
    currency: currencyCode,
    minimumFractionDigits: 2,
  }).format(number);

  // If decimal numbers are `00` remove them from returned value
  const split = formattedPrice.split(`.`);
  if (split[1] === `00`) {
    return split[0];
  }

  // If the formattedPrice returns NaN, formattedPrice = --
  if (formattedPrice.includes(`NaN`)) {
    formattedPrice = `--`;
  }

  return formattedPrice;
};

export const calculateTailoredCost = (count) => {
  // 1. Set default price
  let cost = 2.32;

  // 2. Configure cost
  if (count > 201 && count <= 500) {
    cost = 2.03;
  } else if (count > 500) {
    cost = 1.74;
  }

  // 3. Return the tailored cost
  return cost * count;
};

// Prismic Serializer info: https://prismic.io/docs/javascript/beyond-the-api/html-serializer
export const htmlSerializer = (item, index) => {
  switch (item.type) {
    case `heading2`: // Heading 2
      return (
        <Heading key="index" as="h2">
          {item.text}
        </Heading>
      );

    case `heading3`: // Heading 3
      return (
        <Heading key="index" as="h3">
          {item.text}
        </Heading>
      );

    case `heading4`: // Heading 4
      return (
        <Heading key="index" as="h4">
          {item.text}
        </Heading>
      );

    case `heading5`: // Heading 5
      return (
        <Heading key="index" as="h5">
          {item.text}
        </Heading>
      );

    case `paragraph`: // Paragraph
      return <Text key="index">{item.text}</Text>;

    case `list-item`: // List-Item
      return <li key="index">{item.text}</li>;

    case `image`: // List-Item
      return <img src={item.url} key="index" alt={item.alt} />;

    case `embed`: // Embed
      const videoCode = item.oembed.embed_url.split(`v=`);

      return (
        <Box pt="30px" maxWidth="800px">
          <Video key="index">
            <iframe
              title="TopTekkers blog related video"
              src={`https://www.youtube.com/embed/${videoCode[1]}`}
              width="100%"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </Video>
        </Box>
      );

    default:
      // Always include a default that returns null
      return null;
  }
};
